window.MySubscription = (function (window, $) {
    var MySubscription = {};

    MySubscription.initModal = function () {

        $("body").on("click", ".js-my-subscription", function () {
            $("body").addClass("show-modal");
            return false;
        }).on("click", ".js-btn-close-modal", function () {
            $("body").removeClass("show-modal");
        });

        FormValidator.attach($(".js-my-subscription-modal-form")).on("submit", handleSubmit);
    };

    MySubscription.init = function () {
        MySubscription.registerEvents();
    };
    MySubscription.registerEvents = function () {
        FormValidator.attach($(".js-my-subscription-form")).on("submit", handleSubmit);
    };

    function handleSubmit($form) {
        $form.find(".btn[type='submit']").addClass("btn--loading");
        minimumTimeout(Ajax.post("subscription/preferences/" + encodeURIComponent($form.find(".js-email").val())), 500).then(function () {
            $form.find(".form__loading").removeClass("form__loading--notice").addClass("form__loading--error form__loading--success")
                 .find("p").remove().end()
                 .append($("<p>").text("An email containing a link to manage your subscription has been sent to the email address provided."));
            $form.find(".btn[type='submit']").removeClass("btn--loading");
        }, function (error) {
            $form.find(".form__loading").removeClass("form__loading--notice form__loading--success").addClass("form__loading--error")
                 .find("p").remove().end()
                 .append($("<p>").text(error.message));
            $form.find(".btn[type='submit']").removeClass("btn--loading");
        });
    }

    return MySubscription;
})(window, jQuery);
