window.FormValidator = (function (window, $) {
    var FormValidator = function ($form, options) {
        EventEmitter.call(this);
        $form.data("Validator", this);

        var me = this;
        options = $.extend(true, {
            inputs: {},
            inputOptions: {}
        }, "object" === typeof options ? options : {});

        this.options = options;
        this.$form = $form;

        this.inputs = [];
        $form.find("input,select,textarea").filter(":not(.js-no-validate):not([type=button]):not([type=submit]):not([type=radio])").each(function () {
            var input = new FormInput($(this), $.extend(true, options.inputOptions, options.inputs[$(this).attr("name")]));
            me.inputs.push(input);

            input.on("valid", function (event) {
                me.emit("input:valid", {
                    input: input,
                    event: event
                });
            }).on("invalid", function (event) {
                me.emit("input:invalid", {
                    input: input,
                    event: event
                });
            });
        });

        $form.on("submit", function () {
            if (me.validate()) {
                if (!me.emit("submit", me.$form)) {
                    return true;
                }
            } else {
                me.emit("invalid", me.$form);
            }
            return false;
        });

        setTimeout(function () {
            me.emit("init", me);
        }, 0);
    };

    FormValidator.prototype = {
        validate: function () {
            var valid = true;

            $.each(this.inputs, function (_, input) {
                if (!input.validate()) {
                    valid = false;
                }
            });

            valid = this.emit("validate", this) && valid;

            return valid;
        }
    };

    function FormInput($input, options) {
        EventEmitter.call(this);

        options = $.extend(true, $.extend(true, {}, FormInput.defaultOptions), "object" === typeof options ? options : {});

        this.options = options;

        this.$input = $input;
        this.type = $input.is("[data-type]") ? $input.attr("data-type") : $input.attr("type");
        var $message;

        if ("function" === typeof options.getMessageElement) {
            $message = options.getMessageElement.call(this);
        } else {
            $message = $input.siblings(options.getMessageElement).hide();
        }

        this.$message = $message.hide();

        $input.on(options.events, this.validate.bind(this)).data("Validator", this);
    }

    FormInput.prototype = {
        getMessage: function (type) {
            return "function" === typeof this.options.getMessage ? this.options.getMessage.call(this, type) : this.options.getMessage;
        },
        valid: function () {
            return this._validate().valid;
        },
        validate: function () {
            var result = this._validate();

            if (result.valid) {
                if (this.emit("valid", result)) {
                    if (this.options.hasSuccess && !result.hideMessage) {
                        this.showMessage(this.getMessage("success"), "valid");
                    } else {
                        this.hideMessage();
                    }
                }
            } else {
                if (this.emit("invalid", result)) {
                    this.showMessage(result.message, "invalid");
                }
            }

            return result.valid;
        },
        _validate: function () {
            var valid = true;
            var message = "";
            var value;
            var hideMessage = false;

            if (this.$input.is(":checkbox")) {
                value = this.$input.is(":checked");
                if (!value && !this.$input.is("[data-optional]")) {
                    valid = false;
                    message = this.getMessage("unchecked");
                } else {
                    valid = true;
                }
            } else {
                value = this.$input.val().trim();

                if (value.length === 0) {
                    if (!this.$input.is("[data-optional]")) {
                        valid = false;
                        message = this.getMessage("empty");
                    } else {
                        valid = true;
                        hideMessage = true;
                    }
                } else {
                    switch (this.type) {
                        case "email":
                            if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
                                valid = false;
                                message = this.getMessage("invalidEmail");
                            }
                            break;
                        case "number":
                            var number = this.$input.is("[data-number]") ? this.$input.attr("data-number") : "both";

                            if (isNaN(parseFloat(value)) || !/^-?[0-9]*(\.?[0-9]*)?$/.test(value)) {
                                valid = false;
                                message = this.getMessage("invalidNumber");
                            } else {
                                value = parseFloat(value);

                                if (number === "positive" && value < 0) {
                                    valid = false;
                                    message = this.getMessage("invalidPositiveNumber");
                                } else if (number === "negative" && value > 0) {
                                    valid = false;
                                    message = this.getMessage("invalidNegativeNumber");
                                }
                            }
                            break;
                        case "tel":
                            if (!(/^\d{7,}$/).test(value.replace(/[\s()+\-.]|ext/gi, ""))) {
                                valid = false;
                                message = this.getMessage("invalidPhone");
                            }
                            break;
                    }
                }
            }

            var event = {
                valid: valid,
                message: message
            };

            valid = this.emit("validate", event) && event.valid;
            return {
                valid: valid,
                message: event.message,
                hideMessage: hideMessage
            };
        },
        showMessage: function (message, type) {
            this.$message.text(message).removeClass(this.options.classes.valid + " " + this.options.classes.invalid).addClass(type === "valid" ? this.options.classes.valid : this.options.classes.invalid).show();
            if (type === "invalid") {
                this.$input.addClass("form__element--error");
            } else {
                this.$input.removeClass("form__element--error");
            }
        },
        hideMessage: function () {
            this.$message.hide();
        }
    };
    jQuery.extend(true, FormValidator.prototype, EventEmitter.prototype);
    jQuery.extend(true, FormInput.prototype, EventEmitter.prototype);

    FormValidator.attach = function ($form, options) {
        return new FormValidator($form, options);
    };

    FormInput.defaultOptions = {
        messages: {
            empty: "This value is required",
            unchecked: "This value is required",
            invalidEmail: "Please enter a valid email address",
            success: "",
            invalidNumber: "Please enter a number",
            invalidPositiveNumber: "Please enter a positive number",
            invalidNegativeNumber: "Please enter a negative number",
            invalidPhone: "Please enter a valid phone number"
        },
        hasSuccess: true,
        getMessage: function (type) {
            return this.options.messages[type];
        },
        getMessageElement: ".js-inline-message",
        events: "change blur",
        classes: {
            valid: "form__message--success",
            invalid: "form__message--invalid"
        }
    };

    FormValidator.FormInput = FormInput;

    return FormValidator;
})(window, jQuery);
