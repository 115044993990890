window.ContactForm = (function (window, $) {
    var ContactForm = {};

    ContactForm.init = function () {
        ContactForm.registerEvents();
    };
    ContactForm.registerEvents = function () {
        FormValidator.attach($(".js-contact-form")).on("submit", function ($form) {
            var $btn = $form.find(".btn[type='submit']").addClass("btn--loading");

            grecaptcha.execute().catch(function () {
                $form.find(".form__loading").addClass("form__loading--error").find("p").remove();

                $form.find(".form__loading").append($("<p>").text("There was a problem validating your recaptcha. Please try again."));
                $btn.removeClass("btn--loading");

                grecaptcha.reset();
            });
        });
    };

    window.onRecaptchaSubmit = function (token) {
        console.log("onRecaptchaSubmit", arguments);

        var $form = $(".js-contact-form");
        var $btn = $form.find(".btn[type='submit']").addClass("btn--loading");

        Ajax.post("contact", {
            name: $form.find(".js-name").val(),
            number: $form.find(".js-number").val(),
            email: $form.find(".js-email").val(),
            message: $form.find(".js-message").val(),
            'g-recaptcha-response': token,
        }).then(function () {
            $form.find(".form__row").remove();
            $form.find(".form__loading").removeClass("form__loading--notice").addClass("form__loading--error form__loading--success").find("p").remove().end()
                 .append($("<p>").text("Your enquiry has been submitted."));
        }, function (error) {
            $form.find(".form__loading").addClass("form__loading--error").find("p").remove();

            $form.find(".form__loading").append(error.message.split("\n").map(function (text) {
                return $("<p>").text(text);
            }));
            $btn.removeClass("btn--loading");

            grecaptcha.reset();
        });
    }

    window.onRecaptchaError = function () {
        console.log("onRecaptchaError", arguments);
        var $form = $(".js-contact-form");
        var $btn = $form.find(".btn[type='submit']").addClass("btn--loading");

        $form.find(".form__loading").addClass("form__loading--error").find("p").remove();

        $form.find(".form__loading").append(error.message.split("\n").map(function (text) {
            return $("<p>").text(text);
        }));
        $btn.removeClass("btn--loading");

        grecaptcha.reset();
    }

    return ContactForm;
})(window, jQuery);
