window.Ajax = (function (window, $) {
    var Ajax = {};

    Ajax.request = function (method, action, data) {
        var cancelled = false;
        var promise, jqXHR;
        var csrf = CookieHelper.get("csrf");

        if (method !== "GET") {
            if (!data) {
                data = {
                    csrf: csrf
                };
            } else if ("object" === typeof data) {
                data["csrf"] = csrf;
            } else {
                //Noop Request will be rejected
            }
        }

        promise = new Promise(function (resolve, reject) {
            jqXHR = $.ajax({
                url: "/api/" + action,
                type: method,
                data: data,
                dataType: "json"
            }).done(function (data) {
                if (cancelled) return;

                if (data.success) {
                    resolve(data.data);
                } else {
                    var error = new Error(data.message);
                    error.data = data;
                    reject(error);
                }
            }).fail(function (jqXHR) {
                if (cancelled) return;
                var data = jqXHR.responseJSON;
                var error;
                console.log(data);
                if ("object" === typeof data) {
                    error = new Error(data.message);
                    error.data = jqXHR.responseJSON;
                } else {
                    error = new Error("Connection error occurred");
                    error.data = undefined;
                }
                reject(error);
            });
        });
        if ("undefined" !== typeof promise.__proto__) {
            promise.__proto__.abort = function () {
                cancelled = true;
                if (jqXHR) jqXHR.abort();
                return this;
            };
        }
        return promise;
    };

    Ajax.get = function (action, data) {
        return Ajax.request("GET", action, data);
    };

    Ajax.post = function (action, data) {
        return Ajax.request("POST", action, data);
    };

    Ajax.delete = function (action, data) {
        return Ajax.request("DELETE", action, data);
    };

    Ajax.put = function (action, data) {
        return Ajax.request("PUT", action, data);
    };

    return Ajax;
})(window, jQuery);
