window.SubscribeForm = (function (window, $) {
    function SubscribeForm($form) {
        this.$form = $form;

        $form.data("SubscribeForm", this);

        this.validator = FormValidator.attach($form, {
            inputs: {
                email: {
                    messages: {
                        empty: "Your email address is required"
                    }
                }
            }
        });

        this.validator.on("submit", function ($form) {
            var $btn = $form.find(".btn[type='submit']").addClass("btn--loading");
            Ajax.post("subscribe", {
                email: $form.find(".js-email").val(),
                number: $form.find(".js-number").val()
            }).then(function (data) {
                $form.find(".form__row").remove();
                $form.find(".form__loading").removeClass("form__loading--notice").addClass("form__loading--error form__loading--success").find("p").remove().end()
                     .append($("<p>").text("You will need to confirm your email address before you start receiving email " + (data.number ? "or SMS " : "") + "notifications."))
                     .append($("<p>").text((data.number ? "Once your email address is verified, you can verify your mobile number. " : "") + "To confirm your email address, click the link in the email we have just sent you."));
            }, function (error) {
                $form.find(".form__loading").addClass("form__loading--error").find("p").remove();

                $form.find(".form__loading").append(error.message.split("\n").map(function (text) {
                    return $("<p>").text(text);
                }));
                if (error.data.exception === "AlreadySubscribedException") {
                    $form.find(".form__loading").addClass("form__loading--notice");
                }
                $btn.removeClass("btn--loading");
            });
        });
    }

    SubscribeForm.init = function () {
        $(".js-subscribe-form").each(function () {
            new SubscribeForm($(this));
        });
    };

    return SubscribeForm;
})(window, jQuery);
