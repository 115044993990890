vex.defaultOptions.className = "vex vex-theme-default";

$(function () {
    $("body").on("click", ".js-menu-btn", function () {
        $("body").toggleClass("show-navigation");
    });

    MySubscription.initModal();
    SubscribeForm.init();

    $(window).on("resize", function () {
        if ($(window).outerWidth() > 940) {
            $("body").removeClass("show-navigation show-filter");
        }
    });
});
