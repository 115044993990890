window.ManageSubscriptions = (function (window, $) {
    var ManageSubscriptions = {};

    var subscription;

    ManageSubscriptions.init = function (_subscription) {
        subscription = _subscription;
        ManageSubscriptions.registerEvents();
    };
    ManageSubscriptions.registerEvents = function () {
        FormValidator.attach($(".js-update-subscription-form"), {
            inputs: {
                email: {
                    messages: {
                        empty: "Please enter your email address"
                    }
                }
            }
        }).on("submit", function ($form) {
            $form.find(".btn[type='submit']").addClass("btn--loading");
            Ajax.post("subscription/" + subscription.token + "/", {
                key: subscription.key,
                email: $form.find(".js-email").val()
            }).then(function () {
                $form.find(".form__loading").removeClass("form__loading--notice").addClass("form__loading--error form__loading--success")
                     .find("p").remove().end()
                     .append($("<p>").text("Your subscription has been saved. If you changed your email address you will be sent an email with instructions to confirm your new email address."));

                $form.find(".btn[type='submit']").removeClass("btn--loading");
            }, function (error) {
                $form.find(".form__loading").removeClass("form__loading--notice form__loading--success").addClass("form__loading--error")
                     .find("p").remove().end()
                     .append($("<p>").text(error.message));
                $form.find(".btn[type='submit']").removeClass("btn--loading");
            });
        });

        FormValidator.attach($(".js-add-number-form"), {
            inputs: {
                mobileNumber: {
                    messages: {
                        empty: "Please enter the phone number you would like to add"
                    }
                }
            }
        }).on("submit", function ($form) {
            $form.find(".btn[type='submit']").addClass("btn--loading");
            Ajax.post("subscription/" + subscription.token + "/numbers/", {
                key: subscription.key,
                number: $form.find(".js-number").val()
            }).then(function (data) {
                $form.find(".form__loading").removeClass("form__loading--notice").addClass("form__loading--error form__loading--success")
                     .find("p").remove().end()
                     .append($("<p>").text("This mobile number has been successfully added.  You will need to verify it before receiving any SMS notifications."));
                $form.find(".btn[type='submit']").removeClass("btn--loading");
                $form.find(".js-number").val("");
                ManageSubscriptions.renderNumbers(data.numbers);

                showOTPConfirm(data.number.id);
            }, function (error) {
                $form.find(".btn[type='submit']").removeClass("btn--loading");
                $form.find(".js-number").data("Validator").showMessage(error.message, "invalid");
            });
        });

        FormValidator.attach($(".js-unsubscribe-form"), {
            inputOptions: {
                events: "",
                messages: {
                    unchecked: "You must confirm you would like to unsubscribe first"
                },
                hasSuccess: false
            }
        }).on("submit", function ($form) {
            $form.find(".btn[type='submit']").addClass("btn--loading");
            Ajax.delete("subscription/" + subscription.token + "/", {
                key: subscription.key
            }).then(function () {
                var $content = $("<form>").addClass("form form--confirm")
                                          .append($("<p>").text("You have now been unsubscribed from notifications, you will no longer receive email or SMS notifications."))
                                          .append($("<button>").addClass("btn btn--orange btn--confirm").text("OK").on("click", function () {
                                              vex.closeAll();
                                              return false;
                                          }));

                vex.closeAll();
                vex.open({
                    unsafeContent: $content[0],
                    beforeClose: function () {
                        window.location.href = "/";
                        return false;
                    }
                });
            }, function (error) {
                $form.find(".form__loading").removeClass("form__loading--notice form__loading--success").addClass("form__loading--error")
                     .find("p").remove().end()
                     .append($("<p>").text(error.message));
                $form.find(".btn[type='submit']").removeClass("btn--loading");
            });
        });

        $("body").on("click", ".js-remove-number", function () {
            if (confirm("Are you sure you want to remove this number?")) {
                var me = this;
                $(this).addClass("btn--loading");
                Ajax.delete("subscription/" + subscription.token + "/numbers/" + $(this).parents(".mobile__row").attr("data-id"), {
                    key: subscription.key
                }).then(function (data) {
                    ManageSubscriptions.renderNumbers(data);
                    var $form = $(me).parents(".flex__item--mobile-numbers");
                    $form.find(".form__loading").removeClass("form__loading--notice").addClass("form__loading--error form__loading--success")
                         .find("p").remove().end()
                         .append($("<p>").text("This number has been successfully removed."));
                }, function (error) {
                    var $form = $(me).parents(".flex__item--mobile-numbers");
                    $form.find(".form__loading").removeClass("form__loading--notice form__loading--success").addClass("form__loading--error")
                         .find("p").remove().end()
                         .append($("<p>").text(error.message));
                    $(me).removeClass("btn--loading");
                });
            }
            return false;
        }).on("click", ".js-verify-number", function () {
            showOTPConfirm(parseInt($(this).parents(".mobile__row").attr("data-id")));

            return false;
        }).on("click", ".js-resend-otp", function () {
            var $this = $(this);
            $this.parents("form").find(".js-otp").removeClass("form__element--error").data("Validator").hideMessage();
            Ajax.post("subscription/" + subscription.token + "/numbers/" + $(this).attr("data-id") + "/resend", {
                key: subscription.key
            }).then(function (data) {
                ManageSubscriptions.renderNumbers(data);
                $this.parents("form").find(".js-otp").removeClass("form__element--error").data("Validator").showMessage("", "valid");
            }, function (error) {
                $this.parents("form").find(".js-otp").removeClass("form__element--error").data("Validator").showMessage(error.message, "invalid");
            });
            return false;
        });
    };

    function showOTPConfirm(id) {
        var $content = $("<form>").addClass("form form--mysub");

        $content.append($("<h3>").addClass("vex-dialog-message").text("Confirm your phone number"))
                .append($("<p>").addClass("vex-dialog-message").text("Enter the code we sent to the number you provided to confirm your number. If you would like us to re-send the code please click ")
                                .append($("<a>").attr("href", "#").addClass("js-resend-otp").attr("data-id", id).text("here")))
                .append($("<div>").addClass("vex-dialog-input form__row form__row--mysub form__row--mysub--mobile form__row--mysub--mobile--otp")
                                  .append($("<label>").addClass("form__label").text("Your verification code"))
                                  .append($("<input>").attr({
                                      type: "text",
                                      "data-type": "number",
                                      "class": "js-otp form__element form__element--input form__element--otp",
                                      "data-number": "positive",
                                      "name": "otp"
                                  }))
                                  .append($("<span>").addClass("icon"))
                                  .append($("<span>").addClass("js-inline-message form__message form__message--enter-otp")))
                .append($("<div>").addClass("form__row form__row--mysub--submit").append($("<button>").addClass("btn btn--orange").text("Send").attr("type", "submit")));

        FormValidator.attach($content, {
            inputs: {
                otp: {
                    messages: {
                        empty: "Please enter your confirmation code"
                    }
                }
            }
        }).on("submit", function ($form) {
            var me = this;
            $form.find(".btn[type='submit']").addClass("btn--loading");
            Ajax.post("subscription/" + subscription.token + "/numbers/" + id + "/verify", {
                key: subscription.key,
                otp: $form.find(".js-otp").val()
            }).then(function (data) {
                ManageSubscriptions.renderNumbers(data);
                ManageSubscriptions.showOTPSuccess();
            }, function (error) {
                $form.find(".btn[type='submit']").removeClass("btn--loading");
                me.inputs[0].showMessage(error.message, "error");
            });
        });

        vex.open({
            unsafeContent: $content[0]
        });

        $content.find(".js-otp").trigger("focus");
    }

    ManageSubscriptions.showOTPSuccess = function () {
        var $content = $("<form>").addClass("form form--confirm")
                                  .append($("<p>").text("Your number has now been confirmed"))
                                  .append($("<button>").addClass("btn btn--orange btn--confirm").text("OK").on("click", function () {
                                      vex.closeAll();
                                      return false;
                                  }));

        vex.closeAll();
        vex.open({
            unsafeContent: $content[0]
        });
    };

    ManageSubscriptions.renderNumbers = function (numbers) {
        var $list = $(".js-numbers");

        $list.html("");

        $.each(numbers, function (_, number) {
            var $number = $("<div>").addClass("mobile__row row").attr("data-id", number.id);

            $number.append($("<span>").addClass("mobile__row__number number").text(number.formatted)
                                      .append($("<span>").addClass("number__status number__status--" + (number.confirmed ? "verified" : "unverified")).text(number.confirmed ? "Verified" : "Unverified")));

            var $actions = $("<div>").addClass("number__btn__row");

            if (!number.confirmed) {
                $actions.append($("<button>").addClass("js-verify-number btn btn--verify btn--orange").text("Verify"));
            }
            $actions.append($("<button>").addClass("btn btn--remove js-remove-number").text("Remove"));
            $number.append($actions);

            $list.append($number);
        });
    };

    ManageSubscriptions.updateNumbers = function () {
        Ajax.get("subscription/" + subscription.token + "/numbers/", {
            key: subscription.key
        }).then(function (data) {
            ManageSubscriptions.renderNumbers(data);
        }, function (error) {
            var $form = $(".flex__item--mobile-numbers");
            $form.find(".form__loading").removeClass("form__loading--notice form__loading--success").addClass("form__loading--error")
                 .find("p").remove().end()
                 .append($("<p>").text(error.message));
        });
    };

    return ManageSubscriptions;
})(window, jQuery);
