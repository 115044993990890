window.EventEmitter = (function () {
    function EventEmitter() {
        this.listeners = {};
    }

    EventEmitter.prototype = {
        on: function (event, fn) {
            this._checkEvent(event);
            this.listeners[event].push(fn);
            return this;
        },
        off: function (event, fn) {
            this._checkEvent(event);
            this.listeners[event] = this.listeners[event].filter(function (cur) {
                return cur !== fn;
            });
            return this;
        },
        emit: function (event, data) {
            try {
                this._checkEvent(event);
                if (!data) data = {};
                var me = this;
                var returnValue = true;
                this.listeners[event].forEach(function (fn) {
                    try {
                        returnValue = fn.call(me, data) !== false && returnValue;
                    } catch (e) {
                        console.error("Couldn't call event listener '%s' with data %O", event, data, fn, e);
                    }
                });
                return returnValue;
            } catch (e) {
                console.error(e);
                console.error("Couldn't emit event '%s' with data %O", event, data);
                return false;
            }
        },
        _checkEvent: function (name) {
            if ("string" !== typeof name) throw new Error("Invalid event name passed expected string got " + (typeof name), name);

            if ("undefined" === typeof this.listeners[name]) this.listeners[name] = [];
        }
    };

    return EventEmitter;
})(window, jQuery);
