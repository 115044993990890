window.CookieHelper = (function () {
    return {
        get: function (name) {
            name += "=";
            var ca = document.cookie.split(";");
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === " ") {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
            }
            return null;
        },
        set: function (name, value, expiresDays) {
            var d = new Date();
            d.setTime(d.getTime() + (expiresDays * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = name + "=" + value + "; " + expires;
        }
    };
})(window, jQuery);
