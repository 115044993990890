window.HomePage = (function (window, $) {
    var HomePage = {
        init: function () {
            HomePage.registerEvents();
        },
        registerEvents: function () {
            $("body").on("click", ".js-btn--subscribe, .js-form-close-btn", function () {
                $("body").toggleClass("show-subscription");
                return false;
            }).on("click", ".filter__overlay", function () {
                $("body").removeClass("show-filter show-modal");
                return false;
            });
        }
    };

    return HomePage;
})(window, jQuery);
