window.NotificationList = (function (window, $) {
    var NotificationList = {};

    var nextUrl = false;

    var $list;

    var settings = {
        types: ["1", "2", "3"],
        order: "desc",
        start: undefined,
        end: undefined
    };

    var newSettings = $.extend({}, settings);
    var unconfirmedNewSettings = $.extend({}, settings);

    var loadingjQXHR = false, checkMorejQXHR = false, loadingMore = false;
    var hasMore = true;

    var checkInterval;

    var lastRecentCheck;
    var infiniteScroll = false;
    var userDisabledInfiniteScroll = false;

    var lastScrollTop = $(window).scrollTop();
    var scroll = -66;

    var visibleNotifications = {};

    NotificationList.init = function (_nextUrl) {
        $list = $(".js-notification-list");

        NotificationList.initDatePicker();
        NotificationList.setTypes($(".js-notification-types:checked").map(function () {
            return $(this).val();
        }).get(), true);
        NotificationList.setOrder($(".js-order-by").val(), true);
        NotificationList.setDateRange($(".js-start").val(), $(".js-end").val(), true);
        NotificationList.registerEvents();

        nextUrl = _nextUrl;
        NotificationList.startCheck();
        NotificationList.checkInfiniteScroll();
        NotificationList.checkStickySidebar();
    };

    NotificationList.initDatePicker = function () {
        var $input = $(".js-notifications-date-range").daterangepicker({
            autoUpdateInput: false,
            locale: {
                cancelLabel: "Clear"
            },
            opens: "left",
            drops: "up"
        });

        var datepicker = $input.data("daterangepicker");

        var $container = datepicker.container;

        // Re-order buttons: Apply, Clear
        var $cancel = $container.find(".cancelBtn");
        $cancel.parent().append($cancel.detach());

        var $start_date = $("<input>").addClass("input-mini form-control").attr({
            name: "daterangepicker_start",
            "type": "text"
        });
        var $end_date = $("<input>").addClass("input-mini form-control").attr({
            name: "daterangepicker_end",
            "type": "text"
        });
        var $dates = $([]).add($start_date).add($end_date);

        $container.find(".drp-buttons").before(
            $("<div>").addClass("drp-inputs")
                      .append($("<div>").addClass("daterangepicker_input start").append($start_date))
                      .append($("<div>").addClass("daterangepicker_input end").append($end_date))
        );


        $container.on("mouseenter", "td.available", hoverDate)
                  .on("mouseleave", "td.available", updateFormInputs);

        $input.on("apply.daterangepicker", updateFormInputs);

        $end_date.on("focus", function () {
            datepicker.endDate = null;
            datepicker.setStartDate(datepicker.startDate.clone());
            datepicker.updateView();
        }).on("blur", function () {
            if (!datepicker.endDate) {
                var val = $end_date.val();
                var end = moment(val, datepicker.locale.format);
                if (end.isValid()) {
                    datepicker.setEndDate(end);
                    datepicker.updateView();
                }
            }
        });

        $dates.on("keydown", function (e) {
            if (e.which === 13 /* Enter Key*/) {
                e.preventDefault();
                $(e.target).trigger("change");
            }
        }).on("change", function (e) {
            var isEnd = $(e.target).is("[name='daterangepicker_end']");

            var start = moment($start_date.val(), datepicker.locale.format);
            var end = moment($end_date.val(), datepicker.locale.format);

            if (start.isValid() && end.isValid()) {

                if (isEnd && end.isBefore(start)) {
                    start = end.clone();
                }

                datepicker.setStartDate(start);
                datepicker.setEndDate(end);

                if (isEnd) {
                    $end_date.val(datepicker.endDate.format(datepicker.locale.format));
                } else {
                    $start_date.val(datepicker.startDate.format(datepicker.locale.format));
                }
            }

            datepicker.updateView();
        });

        var oldSetStartDate = datepicker.setStartDate;
        var oldSetEndDate = datepicker.setEndDate;
        var oldUpdateFormInputs = datepicker.updateFormInputs;

        datepicker.setStartDate = function () {
            oldSetStartDate.apply(this, arguments);

            updateFormInputs();
        };

        datepicker.setEndDate = function () {
            oldSetEndDate.apply(this, arguments);

            updateFormInputs();
        };

        datepicker.updateFormInputs = updateFormInputs;

        function hoverDate(e) {
            var $target = $(e.target);

            var $calendar = $target.closest(".calendar");
            var title = $target.attr("data-title");
            var row = title.substr(1, 1);
            var col = title.substr(3, 1);

            var date = $calendar.is(".left") ? datepicker.leftCalendar.calendar[row][col] : datepicker.rightCalendar.calendar[row][col];

            if (datepicker.endDate && !$start_date.is(":focus")) {
                $start_date.val(date.format(datepicker.locale.format));
            } else if (!datepicker.endDate && !$end_date.is(":focus")) {
                $end_date.val(date.format(datepicker.locale.format));
            }
        }

        function updateFormInputs() {
            oldUpdateFormInputs.apply(datepicker, arguments);

            // Don't touch the dates if either of them are focused
            if ($dates.is(":focus")) {
                return;
            }

            if (datepicker.startDate) {
                $start_date.val(datepicker.startDate.format(datepicker.locale.format));
            }
            if (datepicker.endDate) {
                $end_date.val(datepicker.endDate.format(datepicker.locale.format));
            }
        }

        updateFormInputs();
    };

    NotificationList.checkInfiniteScroll = function () {
        if (loadingMore || !hasMore) return;
        if (infiniteScroll) {
            var $main = $("main");
            if ($(window).scrollTop() + $(window).height() > $main.position().top + $main.height() - ($(window).height() / 2.5)) {
                //Within half a scroll to the bottom

                NotificationList.loadMore();
            }
        }
    };
    NotificationList.checkStickySidebar = function () {
        var $sidebar = $(".sidebar");
        var $loader = $(".js-notification-loader");
        var scrollTop = $(document).scrollTop() + $("header").outerHeight();
        if ($(window).outerWidth() < 960) {
            // Sticky Filter bar, mobile
            $sidebar.removeClass("filter--sticky filter--at-bottom");

            var isScrolled = $sidebar.is(".scrolled");
            var listTop = $(".js-notification-list").offset().top;
            if ((!isScrolled && scrollTop > listTop) || (isScrolled && scrollTop > listTop - $("header").outerHeight())) {
                if (!isScrolled) {
                    $sidebar.addClass("scrolled");
                    scroll = -66;
                } else {
                    scroll = Math.min(0, Math.max(-66, scroll + (lastScrollTop - scrollTop)));
                }
                $sidebar.css({"transform": "translate3d(0px, " + (scroll + 60) + "px, 0px)"});

            } else {
                $sidebar.removeClass("scrolled").css({"transform": ""});
                $loader.css({
                    position: "absolute",
                    top: "100px",
                    left: "50%"
                });
            }

            lastScrollTop = scrollTop;
        } else {
            $sidebar.removeClass("scrolled").css({"transform": ""});
            var $wrap = $(".sidebar__wrapper");


            if (scrollTop > $wrap.offset().top && $sidebar.height() + $("header").outerHeight() <= $(window).height() && $sidebar.height() < $wrap.height()) {
                $sidebar.addClass("filter--sticky");

                if (scrollTop + $sidebar.outerHeight() >= $wrap.offset().top + $wrap.outerHeight()) {
                    $sidebar.addClass("filter--at-bottom");
                } else {
                    $sidebar.removeClass("filter--at-bottom");
                }
            } else {
                $sidebar.removeClass("filter--sticky filter--at-bottom");
            }
        }

        var scrollCenter = scrollTop - $("header").outerHeight() + $(window).height() / 2;

        var $list = $(".js-notification-list");

        //Loader position center
        if (scrollCenter > $list.offset().top + 100) {
            if (scrollCenter <= $list.offset().top + $list.outerHeight() - 100 - $loader.outerHeight() / 2) {
                $loader.css({
                    position: "fixed",
                    top: "50%",
                    bottom: "auto",
                    left: $list.offset().left + ($list.outerWidth() / 2)
                });
            } else {
                $loader.css({
                    position: "absolute",
                    top: "auto",
                    bottom: "100px",
                    left: "50%"
                });
            }
        } else {
            $loader.css({
                position: "absolute",
                top: "100px",
                bottom: "auto",
                left: "50%"
            });
        }

    };
    NotificationList.startCheck = function () {
        if (checkInterval) {
            NotificationList.stopCheck();
        }
        lastRecentCheck = (new Date()).getTime() / 1000;
        checkInterval = setInterval(NotificationList.checkForNew, 15 * 1000 /* Every 15 Seconds */);
    };

    NotificationList.stopCheck = function () {
        if (checkInterval) {
            clearInterval(checkInterval);
        }
    };

    NotificationList.checkForNew = function () {
        try {
            if (checkMorejQXHR) checkMorejQXHR.abort();
        } catch (e) {
        }
        checkMorejQXHR = Ajax.get("notifications/recent", {
            from: lastRecentCheck
        });
        checkMorejQXHR.then(function (data) {
            NotificationList.prependNotifications(data.results);
            lastRecentCheck = data.from.timestamp;
            $(".js-new-notifications-error").hide();
        }, function (error) {
            console.error("Couldn't check for new notifications", error);
            $(".js-new-notifications-error").show();
        });
    };

    NotificationList.registerEvents = function () {
        $("body").on("click", ".js-load-more,.js-retry-load-more", function () {
            NotificationList.loadMore();
            return false;
        }).on("click", ".js-retry-check-more", function () {
            $(".js-new-notifications-error,.js-general-error").hide();
            NotificationList.checkForNew();
            return false;
        }).on("click", ".js-retry-notifications", function () {
            $(".js-new-notifications-error,.js-general-error").hide();
            NotificationList.updateNotifications();

            return false;
        }).on("change", ".js-notification-types", function () {
            NotificationList.setTypes($(".js-notification-types:checked").map(function () {
                return $(this).val();
            }).get());
            return false;
        }).on("change", ".js-order-by", function () {
            NotificationList.setOrder($(this).val());
            return false;
        }).on("click", ".js-order-button", function () {
            NotificationList.setOrder($(this).attr("data-value"));
            return false;
        }).on("change", ".js-start", function () {
            NotificationList.setEndDate($(this).val());
            return false;
        }).on("change", ".js-end", function () {
            NotificationList.setStartDate($(this).val());
            return false;
        }).on("change", ".js-infinite-scroll", function () {
            NotificationList.setInfiniteScrolling($(this).is(":checked"));
            if (!$(this).is(":checked")) {
                userDisabledInfiniteScroll = true;
            }
        }).on("apply.daterangepicker", ".js-notifications-date-range", function (ev, picker) {
            NotificationList.setDateRange(picker.startDate.format("YYYY-MM-DD"), picker.endDate.format("YYYY-MM-DD"));
        }).on("cancel.daterangepicker", ".js-notifications-date-range", function (ev, picker) {
            $(this).val("");
            NotificationList.setDateRange(undefined, undefined);
        }).on("click", ".js-open-filter", function () {
            $("body").addClass("show-filter");
            newSettings = $.extend({}, settings);
            unconfirmedNewSettings = $.extend({}, settings);
            scroll = -66;
            $(".sidebar").removeClass("scrolled").css({"transform": ""});
        }).on("click", ".js-filter-cancel", function () {
            $("body").removeClass("show-filter");
            return false;
        }).on("click", ".js-form-option-btn", function () {
            $(this).siblings(".mobile-view").addClass("show");
            return false;
        }).on("click", ".js-filter-inner-cancel", function () {
            var $parent = $(this).parent();
            if (!$parent.is(".mobile-view")) {
                $parent = $parent.parent();
            }
            $parent.removeClass("show");

            var settingNames = $(this).parents("[data-settings]").attr("data-settings").split(",");
            $.each(settingNames, function (_, setting) {
                NotificationList["set" + setting[0].toUpperCase() + setting.substr(1)].call(window, newSettings[setting]);
            });
            return false;
        }).on("click", ".js-filter-select__options", function () {
            $(this).toggleClass("show-options");
            return false;
        }).on("click", ".js-filter-select__options li", function () {
            $(".filter-select__option--active").removeClass("filter-select__option--active");
            NotificationList.setOrder($(this).attr("data-value"));
            return false;
        }).on("click", ".js-filter-inner-apply", function () {
            $(this).parent().parent().removeClass("show");
            var settingNames = $(this).parents("[data-settings]").attr("data-settings").split(",");
            $.each(settingNames, function (_, setting) {
                newSettings[setting] = unconfirmedNewSettings[setting];
            });
            return false;
        }).on("click", ".js-filter-apply", function () {
            settings = newSettings;
            NotificationList.updateNotifications();
            $("body").removeClass("show-filter");
            return false;
        }).on("click", ".js-archive-apply", function () {
            var year = $(this).attr("data-year");

            NotificationList.setDateRange(year + "-01-01", year + "-12-31");
            return false;
        });

        $(window).on("scroll resize", function () {
            if (!$("body").is(".show-filter")) {
                NotificationList.checkInfiniteScroll();
                NotificationList.checkStickySidebar();
            }
        }).on("resize", function () {
            if ($(window).outerWidth() < 960) {
                $(".js-notifications-date-range").data("daterangepicker").hide();
            }
        });
    };
    NotificationList.addLoadMore = function () {
        NotificationList.removeLoadMore();
        $(".js-notification-list").append($("<div>").addClass("content__btn-container js-load-more-container")
                                                    .append($("<a>").attr("href", "#").addClass("btn btn--orange btn--load-notifications js-load-more").text("Load more notifications")));
    };
    NotificationList.removeLoadMore = function () {
        $(".js-load-more-container,.js-all-loaded-container").remove();
    };
    NotificationList.addAllLoaded = function () {
        NotificationList.removeLoadMore();
        $(".js-notification-list").append($("<div>").addClass("notification__message notification__message--complete js-all-loaded-container")
                                                    .append($("<h3>").text("All notifications loaded")));
    };
    NotificationList.loadMore = function () {
        try {
            if (loadingjQXHR) loadingjQXHR.abort();
        } catch (e) {
        }
        $(".js-load-more").addClass("btn--loading");
        $(".js-load-more-error").remove();

        loadingMore = true;
        if (!userDisabledInfiniteScroll && !infiniteScroll) {
            NotificationList.setInfiniteScrolling(true);
        }
        loadingjQXHR = Ajax.get(nextUrl).then(function (data) {
            if (data.results.length > 0) {
                nextUrl = data.next;
                NotificationList.appendNotifications(data.results);
                hasMore = data.results.length === 10;
                $(".js-no-notifications, .js-general-error").hide();
            } else {
                hasMore = false;
            }
            if (hasMore) {
                NotificationList.addLoadMore();
                NotificationList.checkInfiniteScroll();
                NotificationList.checkStickySidebar();
            } else {
                NotificationList.addAllLoaded();
            }
            loadingMore = false;
        }, function () {
            NotificationList.addLoadMore();
            infiniteScroll = false;
            $(".js-load-more-container").before(
                $("<div>").addClass("notification__message notification__message--error js-load-more-error")
                          .append($("<p>").html("We couldn't load any more notifications, please <a href=\"#\" class=\"js-retry-load-more\">retry</a> or reload this page.")));
            loadingMore = false;
        });
    };

    NotificationList.prependNotifications = function (notificaions) {
        notificaions.reverse();
        $.each(notificaions, function (_, notificaion) {
            $list.prepend(NotificationList.getNotificationElement(notificaion));
            visibleNotifications[notificaion.id] = notificaion;
        });
    };
    NotificationList.appendNotifications = function (notificaions) {
        $.each(notificaions, function (_, notificaion) {
            try {
                if ("undefined" !== typeof visibleNotifications[notificaion.id]) {
                    console.error("Tried to add a notification that was already visible!", notificaion);
                    return;
                }
            } catch (e) {
                //Noop
            }
            $list.append(NotificationList.getNotificationElement(notificaion));
            visibleNotifications[notificaion.id] = notificaion;
        });
    };

    NotificationList.getNotificationElement = function (notification) {
        return $(notification.html);
    };

    NotificationList.updateNotifications = function () {
        try {
            if (loadingjQXHR) loadingjQXHR.abort();
        } catch (e) {
        }

        NotificationList.stopCheck();

        $(".js-loading-overlay").addClass("content__overlay--show");
        loadingMore = true;
        loadingjQXHR = minimumTimeout(Ajax.get("notifications", settings), 300).then(function (data) {
            loadingMore = false;

            if (JSON.stringify(settings.types) === JSON.stringify(["1", "2", "3"]) && settings.order === "desc" && !settings.start && !settings.end) {
                NotificationList.startCheck();
            }
            $list.find(".notification").remove();
            visibleNotifications = {};
            $(".js-loading-overlay").removeClass("content__overlay--show");

            if (data.results.length > 0) {
                nextUrl = data.next;
                NotificationList.appendNotifications(data.results);

                if (data.results.length == 10) {
                    NotificationList.addLoadMore();
                    NotificationList.checkInfiniteScroll();
                    hasMore = true;
                } else {
                    NotificationList.addAllLoaded();
                    hasMore = false;
                }

                $(".js-no-notifications").hide();
            } else {
                NotificationList.removeLoadMore();
                hasMore = false;
                $(".js-no-notifications").show();
            }
            $(".js-new-notifications-error,.js-general-error").hide();
            $(".js-load-more-error").remove();

            NotificationList.checkStickySidebar();
        }, function (error) {
            loadingMore = false;
            $list.find(".notification").remove();
            NotificationList.removeLoadMore();
            $(".js-loading-overlay").removeClass("content__overlay--show");
            $(".js-no-notifications,.js-new-notifications-error").hide();
            $(".js-load-more-error").remove();
            $(".js-general-error").show();
        });
    };
    NotificationList.setInfiniteScrolling = function (_infiniteScroll) {
        infiniteScroll = _infiniteScroll;

        if (infiniteScroll) {
            NotificationList.checkInfiniteScroll();
        }

        $(".js-infinite-scroll").attr("checked", infiniteScroll);
    };

    NotificationList.setTypes = function (_types, noUpdate) {
        if ($(window).outerWidth() < 960) {
            unconfirmedNewSettings.types = _types;
        } else {
            settings.types = _types;
            noUpdate || NotificationList.updateNotifications();
        }

        $(".js-types-selected-display").text(_types.map(function (val) {
            return $(".js-notification-types[value='" + val + "']").prev("label").text();
        }).join(", "));

        $(".js-notification-types").prop("checked", false);
        $.each(_types, function (_, type) {
            $(".js-notification-types[value='" + type + "']").prop("checked", true);
        });
    };
    NotificationList.setOrder = function (_order, noUpdate) {
        if ($(window).outerWidth() < 960) {
            unconfirmedNewSettings.order = _order;
        } else {
            settings.order = _order;
            noUpdate || NotificationList.updateNotifications();
        }

        $(".js-order-by-text").text($(".js-order-by option[value='" + _order + "']").text());
        $(".filter-select__option").removeClass("filter-select__option--active");
        $(".filter-select__option[data-value='" + _order + "']").addClass("filter-select__option--active");
        $(".js-order-by").val(_order);
    };
    NotificationList.setStartDate = function (start, noUpdate) {
        if (!start) start = undefined;
        if ($(window).outerWidth() < 960) {
            unconfirmedNewSettings.start = start;
            NotificationList.updateDateDisplays(unconfirmedNewSettings.start, unconfirmedNewSettings.end);
        } else {
            settings.start = start;
            noUpdate || NotificationList.updateNotifications();
            NotificationList.updateDateDisplays(settings.start, settings.end);
        }
    };
    NotificationList.setEndDate = function (end, noUpdate) {
        if (!end) end = undefined;
        if ($(window).outerWidth() < 960) {
            unconfirmedNewSettings.end = end;
            NotificationList.updateDateDisplays(unconfirmedNewSettings.start, unconfirmedNewSettings.end);
        } else {
            settings.end = end;
            noUpdate || NotificationList.updateNotifications();
            NotificationList.updateDateDisplays(settings.start, settings.end);
        }
    };

    NotificationList.updateDateDisplays = function (start, end) {
        var value;
        var datepicker = $(".js-notifications-date-range").data("daterangepicker");

        if ("undefined" === typeof start && "undefined" === typeof end) {
            value = "";
            datepicker.setStartDate(undefined);
            datepicker.setEndDate(undefined);
        } else if ("undefined" === typeof start) {
            value = "Before " + moment(end).format("DD MMM YYYY");
            datepicker.setStartDate(undefined);
            datepicker.setEndDate(moment(end));
        } else if ("undefined" === typeof end) {
            value = "After " + moment(start).format("DD MMM YYYY");
            datepicker.setStartDate(moment(start));
            datepicker.setEndDate(undefined);
        } else {
            value = moment(start).format("DD MMM YYYY") + " - " + moment(end).format("DD MMM YYYY");
            datepicker.setStartDate(moment(start));
            datepicker.setEndDate(moment(end));
        }
        $(".js-notifications-date-range").val(value);
        $(".js-date-range-text").text(value);
        $(".js-start").val(start);
        $(".js-end").val(end);
    };

    NotificationList.setDateRange = function (start, end, noUpdate) {
        NotificationList.setStartDate(start, true);
        NotificationList.setEndDate(end, noUpdate);
    };

    return NotificationList;
})(window, jQuery);
